import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormInstance } from "antd";
import { isArray, isEqual } from "lodash";

import { FormValues } from "@ni/common/types";
import { parseBooleanOrNumber } from "@ni/common/utils";
import {
  LoyaltyProgramTemplatePctValue,
  ParameterTableValue,
  PlanValue,
  ProductValue,
  TenantValue,
} from "@ni/sdk/models";

interface UseHydrateForm {
  form: FormInstance<FormValues>;
  entityFields: LoyaltyProgramTemplatePctValue[] | TenantValue[] | ProductValue[] | ParameterTableValue[] | PlanValue[];
  keys: Partial<{ lists: string[]; strings: string[]; unparsedStrings: string[] }>;
  allowParse?: boolean;
  listSeperator?: string;
}

export const useHydrateForm = (
  { form, entityFields, keys, allowParse = true, listSeperator = "," }: UseHydrateForm,
  deps?: DependencyList,
) => {
  const [isHydrated, setIsHydrated] = useState(false);
  const initialValues = useRef<FormValues>({});
  const entityFieldsRef = useRef<UseHydrateForm["entityFields"]>([]);

  const isEntityFieldsEqual =
    entityFieldsRef.current.length && entityFields.length && isEqual(entityFields, entityFieldsRef.current);
  const isStringsFilled =
    (isArray(keys?.strings) && Boolean(keys.strings.length)) ||
    (isArray(keys?.unparsedStrings) && Boolean(keys.unparsedStrings.length));
  const isListFilled = isArray(keys?.lists) && Boolean(keys.lists.length);

  const values = useMemo(() => {
    const valuesObject: Record<string, string> = {};
    entityFields.forEach(field => {
      const fieldName =
        (field as TenantValue | ProductValue | ParameterTableValue | PlanValue).fieldCode ||
        (field as LoyaltyProgramTemplatePctValue).code;
      if (fieldName) valuesObject[fieldName] = field?.value ?? "";
    });
    return valuesObject;
  }, [entityFields]);

  const handleStringKeys = useCallback(
    (keys?: string[], unparsedKeys?: string[]) => {
      keys?.forEach(key => {
        if (values[key] != null) form.setFieldValue(key, allowParse ? parseBooleanOrNumber(values[key]) : values[key]);
        else form.resetFields([key]);
      });

      unparsedKeys?.forEach(key => {
        if (values[key] != null) form.setFieldValue(key, values[key]);
        else form.resetFields([key]);
      });
    },
    [allowParse, form, values],
  );

  const handleListKeys = useCallback(
    (keys?: string[]) => {
      keys?.forEach(key => {
        if (values[key] != null) {
          if (values[key]) form.setFieldValue(key, values[key]?.split(listSeperator));
        } else form.resetFields([key]);
      });
    },
    [form, listSeperator, values],
  );

  useEffect(() => {
    if (!isEntityFieldsEqual) {
      setIsHydrated(false);
    }
  }, [isEntityFieldsEqual]);

  useEffect(() => {
    if (!isHydrated && entityFields.length > 0 && (isStringsFilled || isListFilled)) {
      if (isStringsFilled) handleStringKeys(keys.strings, keys.unparsedStrings);
      if (isListFilled) handleListKeys(keys.lists);
      initialValues.current = form.getFieldsValue(true) as FormValues;
      entityFieldsRef.current = entityFields;
      setIsHydrated(true);
    }
  }, [
    entityFields.length,
    handleListKeys,
    handleStringKeys,
    isHydrated,
    isListFilled,
    isStringsFilled,
    keys.lists,
    keys.strings,
    deps,
    form,
    entityFields,
    keys.unparsedStrings,
  ]);

  return initialValues.current;
};
