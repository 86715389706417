export const TENANT_PAGES_CODES = {
  details: ["tenantName", "tenant-country", "tenant-currency"],
  "additional-services-details": [
    "nic-ncontrol-flag-def-new",
    "nic-ncontrol-flag-editable",
    "nic-falcon-enable-notif",
    "isCardControlSelected",
    "isFalconSelected",
  ],
  "sms-templates": [
    "17_GT-1",
    "4_START_CONTRACT_IMM_A-1",
    "18_GT-1",
    "4_START_CONTRACT_IMM_C-1",
    "16_GT-1",
    "4_RC-BFA_CARD_EVER_ACTIV-Y-1",
    "4_RC-BFC_CARD_EVER_ACTIV-Y-1",
    "9_GT-1",
    "4_SMS_CP_NEW_PREEMB-1",
    "4_SMS_CP_NEW_PREEMB_NOFEE-1",
    "4_SMS_CP_NEW_PREEMB_PINS-1",
    "4_SMS_CP_NEW_PREEMB_PINS_NOFEE-1",
    "4_SMS_CP_NEW_NOFEE-1",
    "4_SMS_CP_NEW_PINS_NOFEE-1",
    "4_SMS_CP_NEW_PINS-1",
    "4_SMS_CP_NEW-1",
    "12_GT-1",
    "4_SMS_CP_REISS_PINS-1",
    "4_SMS_CP_REISS_PREEMB-1",
    "4_SMS_CP_REISS_PREEMB_NOFEE-1",
    "4_SMS_CP_REISS_PREEMB_PINK-1",
    "4_SMS_CP_REISS_PREEMB_PINK_NOFEE-1",
    "4_SMS_CP_REISS_PREEMB_PINS-1",
    "4_SMS_CP_REISS_PREEMB_PINS_NOFEE-1",
    "4_SMS_CP_REISS_PINS_NOFEE-1",
    "4_SMS_CP_REISS_NOFEE-1",
    "4_SMS_CP_REISS_PINK_NOFEE-1",
    "4_SMS_CP_REISS_PINK-1",
    "4_SMS_CP_REISS-1",
    "14_GT-1",
    "4_SMS_CP_REPL_PINS_NOFEE-1",
    "4_SMS_CP_REPL_PINS-1",
    "4_SMS_CP_REPL_NOFEE-1",
    "4_SMS_CP_REPL-1",
    "4_SMS_CP_REPL_PREEMB-1",
    "4_SMS_CP_REPL_PREEMB_NOFEE-1",
    "4_SMS_CP_REPL_PREEMB_PINS-1",
    "4_SMS_CP_REPL_PREEMB_PINS_NOFEE-1",
    "13_GT-1",
    "4_SMS_CP_RENEXP_PREEMB-1",
    "4_SMS_CP_RENEXP_PREEMB_NOFEE-1",
    "4_SMS_CP_RENEXP_PREEMB_PINK-1",
    "4_SMS_CP_RENEXP_PREEMB_PINK_NOFEE-1",
    "4_SMS_CP_RENEXP_PREEMB_PINS-1",
    "4_SMS_CP_RENEXP-1",
    "4_SMS_CP_RENEXP_NOFEE-1",
    "4_SMS_CP_RENEXP_PINK_NOFEE-1",
    "4_SMS_CP_RENEXP_PINK-1",
    "4_SMS_CP_RENEXP_PINS_NOFEE-1",
    "4_SMS_CP_RENEXP_PINS-1",
    "4_SMS_CP_RENEXP_PREEMB_PINS_NOFEE-1",
    "15_GT-1",
    "4_SMS_CP_RNEW_PINS_NOFEE-1",
    "4_SMS_CP_RNEW-1",
    "4_SMS_CP_RNEW_NOFEE-1",
    "4_SMS_CP_RNEW_PINS-1",
    "10_GT-1",
    "4_SMS_CP_PINPR_NOFEE-1",
    "4_SMS_CP_PINPR-1",
    "11_GT-1",
    "4_SMS_CP_PINRE_NOFEE-1",
    "4_SMS_CP_PINRE-1",
    "4_SMS_CP_PLRE_PREEMB_PINK-1",
    "4_SMS_CP_PLRE_PREEMB_PINK_NOFEE-1",
    "4_SMS_CP_PLRE_PINK_NOFEE-1",
    "4_SMS_CP_PLRE_PINK-1",
    "41_GT-1",
    "3_CHANGE_PIN-1",
    "4_GT-1",
    "4_CLEAR_PIN_ATT-1",
    "5_GT-1",
    "4_SMS_ACTIVATE_CARD-1",
    "6_GT-1",
    "4_SMS_ACTIVATE_CARD-AU_NEW_PR-1",
    "4_SMS_ACTIVATE_CARD-AU_REPL_PR-1",
    "5_CARD_AUTO_RENEW-1",
    "7_GT-1",
    "4_CREDIT_LIMIT_DOC_A-1",
    "4_CREDIT_LIMIT_DOC_C-1",
    "4_CREDIT_LIMIT_DOC_TEMP_A-1",
    "42_GT-1",
    "4_CREDIT_LIMIT_DOC_TEMP_EXP_A-1",
    "43_GT-1",
    "4_SMS_IPP_BAL-1",
    "46_GT-1",
    "4_SMS_IPP_TRANS-1",
    "47_GT-1",
    "8_SMS_STATEMENT-1",
    "8_SMS_STATEMENT_ANY-1",
    "7_SMS_DD_REQUEST-1",
    "7_SMS_DUE_REMINDER-1",
    "4_SMS_AQ_EB_FP_DATE-1",
    "44_GT-1",
    "6_SMS_LPF_CHARGE-1",
    "45_GT-1",
    "19_GT-1",
    "4_SMS_CLOSE_TIMER_A-1",
    "4_RC-BCC-ACC1-V-1",
    "1_GT-1",
    "4_RC-BCC-ACC1-W-1",
    "4_RC-BCC-ACC1-_-1",
    "4_RC-BCC-ACC2-B-1",
    "4_RC-BCC-ACC2-D-1",
    "4_RC-BCC-ACC2-M-1",
    "4_RC-BCC-ACC2-T-1",
    "4_RC-BCC-ACC2-V-1",
    "2_GT-1",
    "4_RC-BCC-ACC2-_-1",
    "4_RC-BCC-ACC2-C-1",
    "4_RC-BCC-ACC2-E-1",
    "4_RC-BCC-ACC2-G-1",
    "4_RC-BCC-ACC2-J-1",
    "4_RC-BCC-ACC2-R-1",
    "4_RC-BCC-ACC2-U-1",
    "4_RC-BCC-ACC2-W-1",
    "4_RC-BCC-CARD-B-1",
    "4_RC-BCC-CARD-D-1",
    "4_RC-BCC-CARD-F-1",
    "4_RC-BCC-CARD-M-1",
    "4_RC-BCC-CARD-P-1",
    "4_RC-BCC-CARD-R-1",
    "4_RC-BCC-CARD-T-1",
    "3_GT-1",
    "4_RC-BCC-CARD-_-1",
    "4_RC-BCC-CARD-C-1",
    "4_RC-BCC-CARD-H-1",
    "4_RC-BCC-CARD-J-1",
    "4_RC-BCC-CARD-L-1",
    "4_RC-BCC-CARD-N-1",
    "4_RC-BCC-CARD-O-1",
    "4_RC-BCC-CARD-S-1",
    "4_RC-BCC-CARD-U-1",
    "2_SMS_ATM-1",
    "2_SMS_CASH-1",
    "2_SMS_CH-DEBIT-1",
    "2_SMS_CH-PAYMENT-1",
    "2_SMS_CREDIT-1",
    "2_SMS_QUASI-CASH-1",
    "2_SMS_RETAIL-1",
    "28_GT-1",
    "2_SMS_ATM_R-1",
    "2_SMS_CASH_R-1",
    "2_SMS_CH-DEBIT_R-1",
    "2_SMS_CH-PAYMENT_R-1",
    "2_SMS_CREDIT_R-1",
    "2_SMS_QUASI-CASH_R-1",
    "2_SMS_RETAIL_R-1",
    "27_GT-1",
    "2_SMS_ATM_J-1",
    "2_SMS_CASH_J-1",
    "2_SMS_CH-DEBIT_J-1",
    "2_SMS_CH-PAYMENT_J-1",
    "2_SMS_CREDIT_J-1",
    "2_SMS_QUASI-CASH_J-1",
    "2_SMS_RETAIL_J-1",
    "26_GT-1",
    "2_SMS_CASH_MAN-1",
    "2_SMS_RETAIL_MAN-1",
    "33_GT-1",
    "2_SMS_CASH_MAN_REF-1",
    "2_SMS_RETAIL_MAN_REF-1",
    "34_GT-1",
    "2_SMS_CASH_MAN_R-1",
    "2_SMS_RETAIL_MAN_R-1",
    "31_GT-1",
    "2_SMS_CASH_MAN_REF_R-1",
    "2_SMS_RETAIL_MAN_REF_R-1",
    "32_GT-1",
    "2_SMS_CASH_MAN_J-1",
    "2_SMS_RETAIL_MAN_J-1",
    "29_GT-1",
    "2_SMS_CASH_MAN_REF_J-1",
    "2_SMS_RETAIL_MAN_REF_J-1",
    "30_GT-1",
    "4_SMS_PAYMENT-1",
    "35_GT-1",
    "4_SMS_PAYMENT_REF-1",
    "36_GT-1",
    "4_SMS_PAYMENT_R-1",
    "37_GT-1",
    "4_SMS_PAYMENT_REF_R-1",
    "38_GT-1",
    "4_SMS_PAYMENT_J-1",
    "39_GT-1",
    "4_SMS_PAYMENT_REF_J-1",
    "40_GT-1",
    "2_SMS_AUTH_NRC_075-1",
    "2_SMS_AUTH_NRC_076-1",
    "2_SMS_AUTH_NRC_096-1",
    "22_GT-1",
    "2_SMS_AUTH_NRC_001-1",
    "2_SMS_AUTH_NRC_003-1",
    "2_SMS_AUTH_NRC_004-1",
    "2_SMS_AUTH_NRC_005-1",
    "2_SMS_AUTH_NRC_007-1",
    "2_SMS_AUTH_NRC_014-1",
    "2_SMS_AUTH_NRC_033-1",
    "2_SMS_AUTH_NRC_034-1",
    "2_SMS_AUTH_NRC_041-1",
    "2_SMS_AUTH_NRC_043-1",
    "2_SMS_AUTH_NRC_051-1",
    "2_SMS_AUTH_NRC_054-1",
    "2_SMS_AUTH_NRC_055-1",
    "2_SMS_AUTH_NRC_057-1",
    "2_SMS_AUTH_NRC_061-1",
    "2_SMS_AUTH_NRC_065-1",
    "21_GT-1",
    "2_SMS_AUTH_NRC_014_R-1",
    "2_SMS_AUTH_NRC_001_R-1",
    "2_SMS_AUTH_NRC_003_R-1",
    "2_SMS_AUTH_NRC_004_R-1",
    "2_SMS_AUTH_NRC_005_R-1",
    "2_SMS_AUTH_NRC_007_R-1",
    "2_SMS_AUTH_NRC_033_R-1",
    "2_SMS_AUTH_NRC_034_R-1",
    "2_SMS_AUTH_NRC_041_R-1",
    "2_SMS_AUTH_NRC_043_R-1",
    "2_SMS_AUTH_NRC_051_R-1",
    "2_SMS_AUTH_NRC_054_R-1",
    "2_SMS_AUTH_NRC_055_R-1",
    "2_SMS_AUTH_NRC_057_R-1",
    "2_SMS_AUTH_NRC_061_R-1",
    "2_SMS_AUTH_NRC_065_R-1",
    "2_SMS_AUTH_NRC_075_R-1",
    "2_SMS_AUTH_NRC_076_R-1",
    "2_SMS_AUTH_NRC_096_R-1",
    "20_GT-1",
    "2_SMS_AUTH_NRC_001_J-1",
    "2_SMS_AUTH_NRC_003_J-1",
    "2_SMS_AUTH_NRC_004_J-1",
    "2_SMS_AUTH_NRC_005_J-1",
    "2_SMS_AUTH_NRC_007_J-1",
    "2_SMS_AUTH_NRC_014_J-1",
    "2_SMS_AUTH_NRC_033_J-1",
    "2_SMS_AUTH_NRC_034_J-1",
    "2_SMS_AUTH_NRC_041_J-1",
    "2_SMS_AUTH_NRC_043_J-1",
    "2_SMS_AUTH_NRC_051_J-1",
    "2_SMS_AUTH_NRC_054_J-1",
    "2_SMS_AUTH_NRC_055_J-1",
    "2_SMS_AUTH_NRC_057_J-1",
    "2_SMS_AUTH_NRC_061_J-1",
    "2_SMS_AUTH_NRC_065_J-1",
    "2_SMS_AUTH_NRC_075_J-1",
    "2_SMS_AUTH_NRC_076_J-1",
    "2_SMS_AUTH_NRC_096_J-1",
    "4_SMS_LTY_RED_CASH_A-1",
    "4_SMS_LTY_RED_CASH_I-1",
    "4_SMS_LTY_RED_CASH_M-1",
    "48_GT-1",
    "4_SMS_LTY_RED_CASH_I_J-1",
    "4_SMS_LTY_RED_CASH_M_J-1",
    "49_GT-1",
    "4_SMS_LTY_RED_CASH_I_R-1",
    "4_SMS_LTY_RED_CASH_M_R-1",
    "50_GT-1",
    "4_SMS_LTY_RED_EXT_A-1",
    "4_SMS_LTY_RED_EXT_I-1",
    "4_SMS_LTY_RED_EXT_M-1",
    "51_GT-1",
    "4_SMS_LTY_RED_EXT_I_J-1",
    "4_SMS_LTY_RED_EXT_M_J-1",
    "52_GT-1",
    "4_SMS_LTY_RED_EXT_I_R-1",
    "4_SMS_LTY_RED_EXT_M_R-1",
    "53_GT-1",
    "8_SMS_LTY_BONUS_W-1",
    "54_GT-1",
  ],
  "sms-general-settings": [
    "enable-sms-glob",
    "nic-sms-t-deliv-ch",
    "default-lang",
    "nic-sms-t-lang1",
    "nic-sms-t-lang2",
  ],
  "client-identification": [
    "nic-unique-client-reg-number",
    "nic-unique-client-number",
    "nic-cs-domain-noncompl",
    "nic-noncompl-auth-block-3",
    "nic-noncompl_level_a-recalc-client-update",
    "nic-noncompl_level_a-recalc-dec-daily-sa",
    "nic-noncompl_c-auth-block-3",
    "nic-noncompl_level_c-suppl-recalc-client-update",
    "nic-noncompl_level_c-suppl-recalc-dec-daily-sa",
    "nic-exid-use",
  ],
  "card-and-pin-maintenance": [
    "nic-plast-exp-same-before",
    "nic-plast-exp-prev-after",
    "nic-plast-exp-prev-before",
    "max-pin-tries",
    "nic-locked-card-rc",
    "nic-phys-restr-for-virt-use",
    "nic-pin-tries-autoreset",
    "nic-pin-tries-drive-block-factor",
    "nic-locked-card-rc",
  ],
  "payment-schemas-services": [
    "nic-token-suspend-unexp",
    "nic-token-close-for-closed",
    "abu-close-stand-rules",
    "nic-abu-optout-unexp",
    "nic-abu-visa_iss_id",
  ],
  "fees-tenant-configuration": [
    "nic-domestic-country",
    "vat-fees-apply",
    "vat-rate",
    "vat-membership-apply",
    "vat-cardprod-fees-apply",
    "vat-trans-fees-apply",
    "vat-markup-fee-apply",
    "vat-other-fees-apply",
    "nic-decl-trans-fee-val-pref-ex_bal_inq",
    "vat-late-fee-apply",
    "vat-ovl-fee-apply",
    "vat-nsf-fee-apply",
    "nic-quasi-cash",
    "nic-decl-trans-fee-val-pref-rc",
  ],
  "credit-products-configuration": [
    "nic-int-base",
    "nic-dd-wr-ask100",
    "nic-dlq-level-to-freeze",
    "nic-past-due-days-calc-mode",
  ],
  "loyalty-Settings": [
    "lty-pnt-mode",
    "lty-refund-mode",
    "nic-lty-accr-rounding-decimals",
    "nic-lty-red-rate-cash-rate",
    "nic-lty-red-rate-ext-rate",
    "lty-welc-bonus-enable",
    "nic-lty-bonus_w-tar0-base",
    "nic-lty-bonus_w-timer-from",
    "nic-lty-bonus_w-timer-per-type",
    "nic-lty-bonus_w-timer-per",
  ],
  "status-management-codes": [
    "nic-fc_posting",
    "nic-fc_report",
    "nic-fc_mf",
    "nic-fc_stmt",
    "nic-fc_stmt_fee",
    "nic-fc_inact_fee",
    "nic-fc_auth",
    "nic-fc_agrm",
    "nic-fc_sms",
    "nic-fc_ncon",
    "nic-r_card_act",
    "nic-r_card_autorepl",
    "nic-fc_tokens",
    "nic-fc_abu_status",
    "nic-bc-prior",
    "nic-bc-name",
    "nic-bc-a1-allow",
    "nic-bc-a2-allow",
    "nic-bc-c1-allow",
    "nic-bc-c1-from-card_st",
    "nic-bc-c1-from-falcon_block",
    "nic-fc_reiss",
    "nic-bc-c1-tib2-set",
    "nic-bc-a1-tib2-rem",
    "nic-bc-a2-tib2-rem",
    "nic-bc-c1-tib2-rem",
    "nic-bc-a1-fa-set",
    "nic-bc-a2-fa-set",
    "nic-bc-c1-fa-set",
    "nic-bc-a1-fa-rem",
    "nic-bc-a2-fa-rem",
    "nic-bc-c1-fa-rem",
    "nic-bc-a1-sa-set",
    "nic-bc-a2-sa-set",
    "nic-bc-c1-sa-set",
    "nic-bc-a1-sa-rem",
    "nic-bc-a2-sa-rem",
    "nic-bc-c1-sa-rem",
    "nic-fc_finch",
    "nic-fc_lpf",
    "nic-fc_ovl",
    "nic-fc_mtp",
    "nic-fc_cb_rep",
    "nic-fc_loy_redm",
    "nic-bc-a2-man-set",
    "nic-bc-a2-man-rem",
    "nic-bc-a2-tib2-set",
    "nic-dlq_lev",
    "nic-bc-a1-man-rem",
    "nic-dlq_perm",
    "nic-bc-a1-man-set",
    "nic-bc-c1-man-set",
    "nic-bc-c1-man-rem",
    "nic-bc-a2-tib1-set",
    "nic-bc-c1-tib1-set",
    "nic-bc-a2-tib1-rem",
    "nic-bc-c1-tib1-rem",
    "nic-bc-a1-tib2-set",
    "nic-fc_dd",
    "nic-fc_loy_accr",
    "nic-fc_ins",
    "nic-bc-c1-from-noncompl_c",
    "nic-bc-a1-from-noncompl",
    "already-edited",
    "bc-a1-man",
    "bc-a2-man",
    "bc-a3-man",
    "bc-a1-tib1",
    "bc-a2-tib1",
    "bc-a3-tib1",
    "bc-a1-tib2",
    "bc-a2-tib2",
    "bc-a3-tib2",
    "bc-a1-fa",
    "bc-a2-fa",
    "bc-a3-fa",
    "bc-a1-sa",
    "bc-a2-sa",
    "bc-a3-sa",
    "nic-bc-a1-tib1-set",
    "nic-bc-a1-tib1-rem",
  ],
};
