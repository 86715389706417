export const PCT_PAGES_CODES = {
  "membership-fees": [
    "amf-suppl-enabled",
    "nic-amf-card-val",
    "nic-amf-card-ex-months",
    "nic-mmf-card-enabled-pct",
    "nic-mmf-card-val",
    "mem_fee_type",
    "nic-amf-acc-ex-months",
    "waive-amf-spend-amount",
    "nic-amf-acc-val",
    "nic-mmf-acc-val",
    "waive-amf-spend",
  ],
  "statement-fee": ["stmt-fee-enabled", "nic-stmt-fee-val"],
  "inactivity-fees": [
    "inact-fee-once-en",
    "nic-inact-fee-once-val",
    "inact-fee-billing-en",
    "nic-inact-fee-billing-val",
  ],
  "overlimit-fee": ["ovl-fee-enable", "nic-ovl-fee-val-base", "nic-ovl-fee-variance"],
  "late-payment-fee": ["lpf-fee-enabled", "nic-lpf-min-base-bal", "nic-lp-date-days", "nic-lpf-val-base"],
  "credit-limit-modification-fees": [
    "crlim-perm-fee-mode",
    "crlim-temp-fee-mode",
    "nic-crlim-perm-fee",
    "nic-crlim-temp-fee",
  ],
  "payment-fees": [
    "paym-fee-enabled",
    "fee-p2p-source-target",
    "nic-paym-online-first-base",
    "nic-paym-online-first-fee-rate",
    "nic-paym-online-first-min",
    "nic-paym-online-first-max",
    "nic-paym-online-subseq-base",
    "nic-paym-online-subseq-fee-rate",
    "nic-paym-online-subseq-min",
    "nic-paym-online-subseq-max",
    "nic-paym-out-def-first-base",
    "nic-paym-out-def-first-fee-rate",
    "nic-paym-out-def-first-min",
    "nic-paym-out-def-first-max",
    "nic-paym-out-def-subseq-base",
    "nic-paym-out-def-subseq-fee-rate",
    "nic-paym-out-def-subseq-min",
    "nic-paym-out-def-subseq-max",
    "nic-paysrc_p2p_def-bf-base",
    "nic-paysrc_p2p_def-bf-fee-rate",
    "nic-paysrc_p2p_def-bf-min",
    "nic-paysrc_p2p_def-bf-max",
    "nic-paysrc_p2p_main-bf-base",
    "nic-paysrc_p2p_main-bf-fee-rate",
    "nic-paysrc_p2p_main-bf-min",
    "nic-paysrc_p2p_main-bf-max",
    "nic-paysrc_p2p_cl-bf-base",
    "nic-paysrc_p2p_cl-bf-fee-rate",
    "nic-paysrc_p2p_cl-bf-min",
    "nic-paysrc_p2p_cl-bf-max",
    "nic-paysrc_p2p_fi-bf-base",
    "nic-paysrc_p2p_fi-bf-fee-rate",
    "nic-paysrc_p2p_fi-bf-min",
    "nic-paysrc_p2p_fi-bf-max",
    "nic-paym_p2p_def-bf-base",
    "nic-paym_p2p_def-bf-fee-rate",
    "nic-paym_p2p_def-bf-min",
    "nic-paym_p2p_def-bf-max",
    "nic-paym_p2p_main-bf-base",
    "nic-paym_p2p_main-bf-fee-rate",
    "nic-paym_p2p_main-bf-min",
    "nic-paym_p2p_main-bf-max",
    "nic-paym_p2p_cl-bf-base",
    "nic-paym_p2p_cl-bf-fee-rate",
    "nic-paym_p2p_cl-bf-min",
    "nic-paym_p2p_cl-bf-max",
    "nic-paym_p2p_fi-bf-base",
    "nic-paym_p2p_fi-bf-fee-rate",
    "nic-paym_p2p_fi-bf-min",
    "nic-paym_p2p_fi-bf-max",
    "nic-paym-alansari-base",
    "nic-paym-alansari-fee-rate",
    "nic-paym-alansari-min",
    "nic-paym-alansari-max",
    "nic-paym-alfardan-base",
    "nic-paym-alfardan-fee-rate",
    "nic-paym-alfardan-min",
    "nic-paym-alfardan-max",
    "nic-paym-atm-base",
    "nic-paym-atm-fee-rate",
    "nic-paym-atm-min",
    "nic-paym-atm-max",
    "nic-paym-banknet-base",
    "nic-paym-banknet-fee-rate",
    "nic-paym-banknet-min",
    "nic-paym-banknet-max",
    "nic-paym-cash-base",
    "nic-paym-cash-fee-rate",
    "nic-paym-cash-min",
    "nic-paym-cash-max",
    "nic-paym-cdm-base",
    "nic-paym-cdm-fee-rate",
    "nic-paym-cdm-min",
    "nic-paym-cdm-max",
    "nic-paym-cheque-base",
    "nic-paym-cheque-fee-rate",
    "nic-paym-cheque-min",
    "nic-paym-cheque-max",
    "nic-paym-collect-base",
    "nic-paym-collect-fee-rate",
    "nic-paym-collect-min",
    "nic-paym-collect-max",
    "nic-paym-offline-base",
    "nic-paym-offline-fee-rate",
    "nic-paym-offline-min",
    "nic-paym-offline-max",
    "nic-paym-swift-base",
    "nic-paym-swift-fee-rate",
    "nic-paym-swift-min",
    "nic-paym-swift-max",
    "nic-paym-transfer-base",
    "nic-paym-transfer-fee-rate",
    "nic-paym-transfer-min",
    "nic-paym-transfer-max",
    "nic-paym-uaeexc-base",
    "nic-paym-uaeexc-fee-rate",
    "nic-paym-uaeexc-min",
    "nic-paym-uaeexc-max",
    "nic-paym-uaefts-base",
    "nic-paym-uaefts-fee-rate",
    "nic-paym-uaefts-min",
    "nic-paym-uaefts-max",
    "nic-paym-dd-base",
    "nic-paym-dd-fee-rate",
    "nic-paym-dd-min",
    "nic-paym-dd-max",
    "nic-paym-uaedds-base",
    "nic-paym-uaedds-fee-rate",
    "nic-paym-uaedds-min",
    "nic-paym-uaedds-max",
    "nic-paymrev-nsf-base",
    "nic-paymrev-nsf-fee-rate",
    "nic-paymrev-nsf-min",
    "nic-paymrev-nsf-max",
  ],
  "currency-conversion-fees": [
    "nic-ips-dcc-cash-base",
    "nic-ips-dcc-cash-fee-rate",
    "nic-ips-dcc-cash-min",
    "nic-ips-dcc-cash-max",
    "nic-ips-dcc-retail-base",
    "nic-ips-dcc-retail-fee-rate",
    "nic-ips-dcc-retail-min",
    "nic-ips-dcc-retail-max",
    "nic-ips-markup-cash-fee-rate",
    "nic-ips-markup-retail-fee-rate",
    "fx-fee-enabled",
    "nic-ips-markup-fees-fin-only-retail",
    "nic-ips-dcc-fees-fin-only-retail",
  ],
  "decline-fee": ["decline-fee-enabled", "nic-decl-trans-fee-val", "nic-decl-trans-fee-max-nr"],
  "loyalty-settings": [
    "nic-lty-red-rate-cash-rate",
    "nic-lty-red-rate-ext-rate",
    "nic-lty-bonus_w-tar0-base",
    "nic-lty-accr-rounding-decimals",
    "lty-enrol-fee-en",
    "nic-lty-enrol-fee-val",
  ],
  "credit-limit": ["nic-cash-limit-acc-rate", "nic-ovl-auth-var", "nic-cash-limit-card-rate"],
  "payment-due": [
    "nic-dlq-date-days",
    "nic-due-date-days",
    "nic-mtp-val-base",
    "nic-mtp-val-rate",
    "nic-sms-due-reminder-days",
  ],
  "murabaha-profit-calculation": ["nic-fp-date-days", "int-start", "int-start-numb", "murab-profit-rate"],
  "transaction-restrictions": [
    "nic-pers-restr-moto-en",
    "nic-pers-restr-ecomm-en",
    "nic-pers-restr-contactless-en",
    "nic-pers-restr-fallback-en",
    "nic-pers-restr-for-country-en",
    "nic-pers-restr-cash-atm-en",
    "nic-pers-restr-all-dr-en",
    "nic-mcc-restr-card-pers-rules",
  ],
  "account-limits-daily": [
    "acc-daily-all-cash-enable",
    "acc-daily-all-retail-enable",
    "acc-daily-all-total-enable",
    "nic-a-d-total-enable",
    "nic-a-d-retail-for-enable",
    "nic-a-d-retail-enable",
    "nic-a-d-retail-dom-enable",
    "nic-a-d-ecomm-enable",
    "nic-a-d-cash-pos-enable",
    "nic-a-d-cash-for-enable",
    "nic-a-d-cash-enable",
    "nic-a-d-cash-dom-enable",
    "nic-a-d-cash-atm-enable",
    "nic-a-d-cash-atm-max-am",
    "nic-a-d-cash-atm-max-nr",
    "nic-a-d-cash-atm-max-single",
    "nic-a-d-cash-dom-max-am",
    "nic-a-d-cash-dom-max-nr",
    "nic-a-d-cash-dom-max-single",
    "nic-a-d-cash-max-am",
    "nic-a-d-cash-max-nr",
    "nic-a-d-cash-max-single",
    "nic-a-d-cash-for-max-am",
    "nic-a-d-cash-for-max-nr",
    "nic-a-d-cash-for-max-single",
    "nic-a-d-cash-pos-max-am",
    "nic-a-d-cash-pos-max-nr",
    "nic-a-d-cash-pos-max-single",
    "nic-a-d-ecomm-max-am",
    "nic-a-d-ecomm-max-nr",
    "nic-a-d-ecomm-max-single",
    "nic-a-d-retail-dom-max-am",
    "nic-a-d-retail-dom-max-nr",
    "nic-a-d-retail-dom-max-single",
    "nic-a-d-retail-max-am",
    "nic-a-d-retail-max-nr",
    "nic-a-d-retail-max-single",
    "nic-a-d-retail-for-max-am",
    "nic-a-d-retail-for-max-nr",
    "nic-a-d-retail-for-max-single",
    "nic-a-d-total-max-am",
    "nic-a-d-total-max-nr",
    "nic-a-d-total-max-single",
  ],
  "account-limits-billing-period": [
    "card-billing-all-cash-enable",
    "card-billing-all-retail-enable",
    "card-billing-all-total-enable",
    "nic-a-b-total-max-single",
    "nic-a-b-total-max-am",
    "nic-a-b-total-max-nr",
    "a-b-total-enable",
    "nic-a-b-retail-for-max-single",
    "nic-a-b-retail-for-max-am",
    "nic-a-b-retail-for-max-nr",
    "a-b-retail-for-enable",
    "nic-a-b-retail-max-single",
    "nic-a-b-retail-max-am",
    "nic-a-b-retail-max-nr",
    "a-b-retail-enable",
    "nic-a-b-cash-for-max-single",
    "nic-a-b-cash-for-max-am",
    "nic-a-b-cash-for-max-nr",
    "a-b-cash-for-enable",
    "nic-a-b-cash-max-single",
    "nic-a-b-cash-max-am",
    "nic-a-b-cash-max-nr",
    "a-b-cash-enable",
  ],
  "card-limits-daily": [
    "card-daily-all-cash-enable",
    "nic-c-d-cash-enable",
    "nic-c-d-cash-atm-enable",
    "nic-c-d-cash-pos-enable",
    "card-daily-all-retail-enable",
    "nic-c-d-retail-enable",
    "nic-c-d-ecomm-enable",
    "card-daily-all-total-enable",
    "nic-c-d-total-enable",
    "nic-c-d-cash-atm-max-am",
    "nic-c-d-cash-atm-max-nr",
    "nic-c-d-cash-atm-max-single",
    "nic-c-d-cash-max-am",
    "nic-c-d-cash-max-nr",
    "nic-c-d-cash-max-single",
    "nic-c-d-cash-pos-max-am",
    "nic-c-d-cash-pos-max-nr",
    "nic-c-d-cash-pos-max-single",
    "nic-c-d-ecomm-max-am",
    "nic-c-d-ecomm-max-nr",
    "nic-c-d-ecomm-max-single",
    "nic-c-d-retail-max-am",
    "nic-c-d-retail-max-nr",
    "nic-c-d-retail-max-single",
    "nic-c-d-total-max-am",
    "nic-c-d-total-max-nr",
    "nic-c-d-total-max-single",
  ],
  "card-limits-monthly": [
    "card-monthly-all-cash-enable",
    "nic-c-m-cash-enable",
    "nic-c-m-cash-for-enable",
    "card-monthly-all-retail-enable",
    "nic-c-m-retail-for-enable",
    "nic-c-m-retail-enable",
    "card-monthly-all-total-enable",
    "nic-c-m-total-enable",
    "nic-c-m-cash-for-max-am",
    "nic-c-m-cash-for-max-nr",
    "nic-c-m-cash-for-max-single",
    "nic-c-m-cash-max-am",
    "nic-c-m-cash-max-nr",
    "nic-c-m-cash-max-single",
    "nic-c-m-retail-for-max-am",
    "nic-c-m-retail-for-max-nr",
    "nic-c-m-retail-for-max-single",
    "nic-c-m-retail-max-am",
    "nic-c-m-retail-max-nr",
    "nic-c-m-retail-max-single",
    "nic-c-m-total-max-am",
    "nic-c-m-total-max-nr",
    "nic-c-m-total-max-single",
  ],
  "card-limits-billing-period": [
    "card-monthly-all-retail-enable",
    "card-monthly-all-cash-enable",
    "c-b-cash-atm-enable",
    "nic-c-b-cash-atm-max-nr",
    "nic-c-b-cash-atm-max-am",
    "nic-c-b-cash-atm-max-single",
    "c-b-retail-enable",
    "nic-c-b-retail-max-nr",
    "nic-c-b-retail-max-am",
    "nic-c-b-retail-max-single",
  ],
  "additional-rules": ["nic-usage-paym-u01-rules", "nic-usage-paym-u02-rules", "nic-usage-paym-u03-rules"],
  "account-limits": [
    "b-paym-a-total-enabled",
    "d-paym-a-total-enabled",
    "d365-paym-a-total-enabled",
    "e-paym-a-total-enabled",
    "nic-b-paym-a-total-single",
    "paym-e-enabled",
    "paym-d365-enabled",
    "paym-d-enabled",
    "paym-b-enabled",
    "nic-e-paym-a-total-single",
    "nic-e-paym-a-total-nr",
    "nic-e-paym-a-total-am",
    "nic-d365-paym-a-total-single",
    "nic-d365-paym-a-total-nr",
    "nic-d365-paym-a-total-am",
    "nic-d-paym-a-total-single",
    "nic-d-paym-a-total-nr",
    "nic-d-paym-a-total-am",
    "nic-b-paym-a-total-nr",
    "nic-b-paym-a-total-am",
  ],
  "card-limits": [
    "d-paym-c-total-enabled",
    "d365-paym-c-total-enabled",
    "m-paym-c-total-enabled",
    "nic-d-paym-c-total-am",
    "nic-d-paym-c-total-nr",
    "nic-d-paym-c-total-single",
    "nic-d365-paym-c-total-am",
    "nic-d365-paym-c-total-nr",
    "nic-d365-paym-c-total-single",
    "nic-m-paym-c-total-am",
    "nic-m-paym-c-total-nr",
    "nic-m-paym-c-total-single",
    "paym-b-enabled",
    "paym-d-enabled",
    "paym-d365-enabled",
  ],
  "joining-fee": ["jf-acc-enabled", "nic-jf-acc-val"],
  "card-production-fees": [
    "nic-cp-pinre-add-opt-enabled",
    "nic-cp-pinpr-add-opt-enabled",
    "nic-cp-rnew-add-opt-enabled",
    "nic-cp-plre-add-opt-enabled",
    "nic-cp-reiss-add-opt-enabled",
    "nic-cp-renexp-add-opt-enabled",
    "nic-cp-repl-add-opt-enabled",
    "nic-cp-new-add-opt-enabled",
    "nic-cp-cp-fees-enabled",
  ],
  "card-transaction-fees": [
    "ips-trans-fee-enabled",
    "nic-ips-fees-fin-only",
    "ips-cash-fee-enabled",
    "ips-quasi-fee-enabled",
    "nic-ips-retail-qsi-bf-base",
    "nic-ips-retail-qsi-bf-fee-rate",
    "nic-ips-retail-qsi-bf-min",
    "nic-ips-retail-qsi-bf-max",
    "nic-ips-cash-atm-def-bf-base",
    "nic-ips-cash-atm-def-bf-fee-rate",
    "nic-ips-cash-atm-def-bf-min",
    "nic-ips-cash-atm-def-bf-max",
    "nic-ips-cash-pos-def-bf-base",
    "nic-ips-cash-pos-def-bf-fee-rate",
    "nic-ips-cash-pos-def-bf-max",
    "nic-ips-cash-pos-def-bf-min",
    "def-tier-min-atm",
    "def-tier-min-cash",
    "nic-ips-cash-pos-dom-t1-max",
    "nic-ips-cash-pos-onus-t1-max",
    "nic-ips-cash-pos-def-t1-max",
    "nic-ips-cash-atm-dom-t1-max",
    "nic-ips-cash-atm-onus-t1-max",
    "nic-ips-cash-atm-def-t1-max",
    "nic-ips-cash-pos-def-bf-min",
    "ips-trans-fee-enabled",
    "atm-reg-enable",
    "atm-amount-enable",
    "cash-reg-enable",
    "cash-amount-enable",
    "retail-reg-enable",
    "refund-reg-enable",
    "p2p-deb-reg-enable",
    "p2p-paym-reg-enable",
    "quasi-reg-enable",
  ],
  "tawarruq-profit-calculation": [
    "tawarruq-profit-type",
    "tawarruq-profit-percentage",
    "tawarruq-profit-amount",
    "tawarruq-sales-fee",
    "tawarruq-sales-fee-type",
    "tawarruq-sales-fee-percentage",
    "tawarruq-sales-fee-amount",
    "nic-fp-date-days",
  ],
  "interest-calculation": [
    "nic-due-date-days",
    "nic-int-rate-retail",
    "nic-int-rate-cash",
    "int-start",
    "int-start-numb",
    "nic-fp-date-days",
    "zero-int-fees",
    "nic-int-rate-zero-retail-amf",
    "nic-int-rate-zero-retail-lpf",
    "nic-int-rate-zero-retail-ovl",
    "nic-int-rate-zero-retail-nsf",
    "nic-int-rate-zero-retail-ins",
    "nic-int-rate-zero-cash-ins",
    "zero-int-ins",
  ],
};
