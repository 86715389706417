/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Divider,
  Flex,
  Form,
  Modal,
  notification,
  Space,
  Table,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ColumnsType, ColumnType } from "antd/lib/table";
import cn from "classnames";
import { useParams } from "react-router-dom";

import { ClearOutlined, ReloadOutlined } from "@ant-design/icons";
import { notSpecified, ProductBcParams } from "@ni/common/constants";
import { useReduxState, useToggleFeature } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { TenantApi } from "@ni/sdk/apis";
import { BlockCode, ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import { NetworkForm } from "../FormInput";
import { SMCViewToggles } from "../SMCViewToggles";
import { TooltipInfo } from "../TooltipInfo";

import {
  BlockCodeObject,
  BlockCodes,
  BlockInput,
  booleanFields,
  codes,
  fieldCodeLabels,
  fieldCodesToMap,
} from "./constants";
import { EditableCell } from "./EditableCell";

import styles from "./styles.module.scss";

const tenantServiceApi = new TenantApi();
const labelWidth: number = 350;
const blockCodeWidth: number = 250;

const components = {
  body: {
    cell: EditableCell,
  },
};

const expandable = {
  defaultExpandAllRows: true,
};

interface TableRow {
  key: string;
  label: JSX.Element | string;
  isCategory?: boolean;
  tooltip?: string;
  children?: TableRow[];
}

interface Props {
  dataSource: BlockCodeObject[];
  onReset: (code: string) => void;
}

const SMCTable = React.memo(({ dataSource, onReset }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [isPrintMode] = useReduxState<boolean>("isPrintMode", false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [visibleColumns, setVisibleColumns] = useState<string[]>(codes);
  const [codeToClear, setCodeToClear] = useState<string | null>(null);
  const [codeToRestore, setCodeToRestore] = useState<string | null>(null);

  const { isDisabled: isCardControlDisabled } = useToggleFeature("CARDCNTRL");

  const { id: tenantId } = useParams<{ id: string }>();

  const { initialValues, dependencies } = useMemo(() => {
    const blockCodeMap = tenant.blockCodes?.reduce(
      (acc, x) => {
        const key = `${x.code}@${x.fieldCode}`;
        acc[key] = x.value ?? "";

        if (booleanFields.includes(x.fieldCode!)) {
          acc[key] = String(x.value === "true" || x.value === "Y");
        }

        return acc;
      },
      {} as Record<string, string | undefined>,
    );

    const initialValues =
      tenant.blockCodes?.reduce(
        (acc, x) => {
          const key = `${x.code}@${x.fieldCode}`;
          acc[key] = blockCodeMap?.[key] ?? x.value;

          const matchedFieldCode = fieldCodesToMap.find(fc => x.fieldCode?.includes(fc));

          if (matchedFieldCode) {
            const setField = blockCodeMap?.[`${x.code}@nic-${matchedFieldCode}-set`] ?? "false";
            const remField = blockCodeMap?.[`${x.code}@nic-${matchedFieldCode}-rem`] ?? "false";

            const fieldValue =
              setField === "true" && remField === "false"
                ? "10"
                : setField === "false" && remField === "true"
                  ? "01"
                  : setField === "true" && remField === "true"
                    ? "11"
                    : "00";

            acc[`${x.code}@${matchedFieldCode}`] = fieldValue;
          }

          return acc;
        },
        {} as Record<string, string | undefined>,
      ) ?? {};

    const fieldCodes = ["nic-bc-a1-allow", "nic-bc-a2-allow", "nic-bc-c1-allow", "nic-dlq_lev"];
    const dependencies = codes.flatMap(code => fieldCodes.map(suffix => `${code}@${suffix}`));

    return { initialValues, dependencies };
  }, [tenant.blockCodes]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setIsLoading(false);
    }
  }, [form, initialValues, setIsLoading]);

  const columns: ColumnsType<TableRow> = useMemo(() => {
    const getColumnTitle = (code: string) => {
      const { name, priority } = dataSource.find(x => x.code === code) || {};
      const isClearDisabled = name === notSpecified || priority === "00" || code === "_";

      return (
        <Flex justify="space-between" align="center" className="w-p-100">
          <div>
            <span className="m-r-8">{code}</span>
            <span className={cn(styles["name-style"], name === notSpecified ? styles["unfilled"] : styles["filled"])}>
              {name}
            </span>
          </div>

          {!isPrintMode && (
            <Flex>
              <Button
                type="link"
                onClick={() => setCodeToRestore(code)}
                icon={
                  <TooltipInfo tooltipProps={{ title: "Return to defaults" }}>
                    <ReloadOutlined />
                  </TooltipInfo>
                }
                className={styles["action-button"]}
              />
              <Button
                type="link"
                onClick={() => setCodeToClear(code)}
                disabled={isClearDisabled}
                icon={
                  <TooltipInfo tooltipProps={{ title: "Clear" }}>
                    <ClearOutlined />
                  </TooltipInfo>
                }
                className={styles["action-button"]}
              />
            </Flex>
          )}
        </Flex>
      );
    };

    return [
      {
        key: "label",
        dataIndex: "label",
        rowScope: "row",
        fixed: "left" as const,
        width: labelWidth,
        ellipsis: true,
        hidden: false,
        editable: false,
        render: (text: string, record) => (
          <TooltipInfo
            label={text}
            code={!record.isCategory ? record.key : undefined}
            tooltipProps={record.tooltip ? { title: record.tooltip } : {}}
          />
        ),
      },
      ...codes.map(code => {
        const column: ColumnType<TableRow> & { editable: boolean } = {
          title: () => getColumnTitle(code),
          key: code,
          dataIndex: code,
          width: blockCodeWidth,
          ellipsis: !isPrintMode,
          hidden: !visibleColumns.includes(code),
          editable: true,
        };

        if (!column.dataIndex) return column;

        if (column.editable) {
          column.onCell = (record: TableRow) => {
            if (record.isCategory) return {};

            let disabled: boolean = false;
            const { dataIndex, editable } = column as { dataIndex: string; editable: boolean };

            const accAutoEnabled = form.getFieldValue(`${dataIndex}@nic-bc-a1-allow`) === "true";
            const accManualEnabled = form.getFieldValue(`${dataIndex}@nic-bc-a2-allow`) === "true";
            const cardEnabled = form.getFieldValue(`${dataIndex}@nic-bc-c1-allow`) === "true";

            if (
              (record.key.startsWith("bc-a1") && !accAutoEnabled) ||
              (record.key.startsWith("bc-a2") && !accManualEnabled) ||
              (record.key.startsWith("bc-c1") && !cardEnabled)
            ) {
              disabled = true;
            }

            // If either "Assigned due to primary client ID expiration (account holder)" or
            // "Assigned due to delinquency level" do not have "Account automatically" enabled,
            // then these fields will be disabled.
            if ((record.key === "nic-bc-a1-from-noncompl" || record.key === "nic-dlq_lev") && !accAutoEnabled) {
              disabled = true;
            }

            // If either "Assigned due to client ID expiration (cardholder)", "Assigned by card status", or
            // "Assigned by Falcon block" do not have "Card" enabled, then these fields will be disabled.
            if (
              (record.key === "nic-bc-c1-from-noncompl_c" ||
                record.key === "nic-bc-c1-from-card_st" ||
                record.key === "nic-bc-c1-from-falcon_block") &&
              !cardEnabled
            ) {
              disabled = true;
            }

            // If "Delinquency status management code is permanent" does not have "Account automatically"
            // enabled AND if "Assigned due to delinquency level" is not specified, then this field will
            // be disabled.
            if (record.key === "nic-dlq_perm") {
              const isDelqNotSpecified = (form.getFieldValue(`${dataIndex}@nic-dlq_lev`) ??
                form.getFieldValue(`${dataIndex}@nic-dlq_lev`) === "") as boolean;

              if (!(accAutoEnabled && isDelqNotSpecified)) disabled = true;
            }

            if (
              // Affected features codes
              (record.key === "nic-fc_mtp" ||
                record.key === "nic-fc_cb_rep" ||
                record.key === "nic-fc_dd" ||
                record.key === "nic-fc_stmt" ||
                record.key === "nic-fc_report" ||
                // Waivers codes
                record.key === "nic-fc_finch" ||
                record.key === "nic-fc_lpf" ||
                record.key === "nic-fc_ovl" ||
                record.key === "nic-fc_stmt_fee") &&
              !(accAutoEnabled || accManualEnabled)
            ) {
              disabled = true;
            }

            if (disabled && form.getFieldValue(`${dataIndex}@${record.key}`)) {
              form.setFieldValue(`${dataIndex}@${record.key}`, null);
            }

            return {
              form,
              record: record?.[dataIndex],
              editable,
              dataIndex,
              disabled,
            };
          };
        }

        return column;
      }),
    ];
  }, [dataSource, form, isPrintMode, visibleColumns]);

  const { prodsExistWithCreditOnUs, prodsExistWithToken, prodsExistWithOnUs, prodsExistWithABU } = useMemo(
    () => ({
      prodsExistWithCreditOnUs: tenant.products?.some(
        product =>
          product.productValues?.some(({ fieldCode, value }) => fieldCode === "balance-owner" && value === "CMS") &&
          product.productValues?.some(({ fieldCode, value }) => fieldCode === "product-type" && value === "Credit"),
      ),
      prodsExistWithToken: tenant.products?.some(
        product =>
          product.productValues?.some(
            ({ fieldCode, value }) => fieldCode === "nic-card-subtype-token-used" && value === "true",
          ) ||
          product.productValues?.some(
            ({ fieldCode, value }) => fieldCode === "nic-card-subtype-token-mada-used" && value === "true",
          ),
      ),
      prodsExistWithOnUs: tenant.products?.some(product =>
        product.productValues?.some(({ fieldCode, value }) => fieldCode === "balance-owner" && value === "CMS"),
      ),
      prodsExistWithABU: tenant.products?.some(product =>
        product.productValues?.some(
          ({ fieldCode, value }) => fieldCode === "nic-card-subtype-abu-active" && value === "true",
        ),
      ),
    }),
    [tenant.products],
  );

  const tableData = useMemo(() => {
    const recursivelyBuildTable = (data: BlockCodes[]): TableRow[] => {
      return data
        .map(x => {
          if (x.isCategory) {
            if (x.name === "Waivers") {
              const productsExistWithCMS = tenant.products?.some(product =>
                product.productValues?.some(({ fieldCode, value }) => fieldCode === "balance-owner" && value === "CMS"),
              );
              if (!productsExistWithCMS) return null;
            }

            return {
              key: x.name || "",
              label: <TooltipInfo label={x.name || ""} tooltipProps={x.tooltip ? { title: x.tooltip } : {}} />,
              isCategory: true,
              children: recursivelyBuildTable(x.items),
            };
          }

          if (x.fieldCode === "nic-fc_ncon" && (isCardControlDisabled || !tenant.isCardControlSelected)) return null;
          if (x.fieldCode === "nic-bc-c1-from-falcon_block" && !tenant.isFalconSelected) return null;
          if (x.fieldCode === "nic-fc_tokens" && !prodsExistWithToken) return null;
          if (x.fieldCode === "nic-fc_abu_status" && !prodsExistWithABU) return null;
          if ((x.fieldCode === "nic-fc_posting" || x.fieldCode === "nic-fc_stmt") && !prodsExistWithOnUs) return null;
          if (
            (x.fieldCode === "nic-dlq_lev" ||
              x.fieldCode === "nic-fc_dd" ||
              x.fieldCode === "nic-fc_finch" ||
              x.fieldCode === "nic-fc_lpf" ||
              x.fieldCode === "nic-fc_ovl" ||
              x.fieldCode === "nic-fc_mtp" ||
              x.fieldCode === "nic-fc_cb_rep" ||
              x.fieldCode === "nic-fc_report") &&
            !prodsExistWithCreditOnUs
          )
            return null;

          return {
            key: x.fieldCode || "",
            label: x.label || "",
            tooltip: x.tooltip,
            ...codes.reduce<Record<string, { code: string; fieldCode: string; input: BlockInput }>>((acc, code) => {
              acc[code] = {
                code,
                fieldCode: x.fieldCode,
                input: x.input,
              };
              return acc;
            }, {}),
          };
        })
        .filter(row => row !== null);
    };

    return recursivelyBuildTable(fieldCodeLabels);
  }, [
    isCardControlDisabled,
    prodsExistWithABU,
    prodsExistWithCreditOnUs,
    prodsExistWithOnUs,
    prodsExistWithToken,
    tenant.isCardControlSelected,
    tenant.isFalconSelected,
    tenant.products,
  ]);

  const validateRequiredFields = (formValues: FormValues, dataSource: BlockCodeObject[], code: string) => {
    const requiredFieldCodes = ["nic-bc-name", "nic-bc-prior"];
    const mandatoryAtLeastOneCodes = ["nic-bc-a1-allow", "nic-bc-a2-allow", "nic-bc-c1-allow"];
    const block = dataSource.find(entry => entry.code === code);

    const missingFields = requiredFieldCodes.filter(fieldCode => {
      const formFieldKey = `${code}@${fieldCode}`;
      const formValue = formValues[formFieldKey];

      if (formValue === undefined || formValue === null || formValue === "") {
        const blockCodeValue = block?.blockCodeValues.find(bc => bc.fieldCode === fieldCode);

        return !blockCodeValue?.value;
      }

      return false;
    });

    const atLeastOneFilled = mandatoryAtLeastOneCodes.some(fieldCode => {
      const formFieldKey = `${code}@${fieldCode}`;
      const formValue = formValues[formFieldKey];

      if (formValue !== undefined && formValue !== null && formValue !== "") {
        return true;
      }

      const blockCodeValue = block?.blockCodeValues.find(bc => bc.fieldCode === fieldCode);
      return !!blockCodeValue?.value;
    });

    if (!atLeastOneFilled) {
      missingFields.push("atLeastOneFilled");
    }

    return missingFields;
  };

  const getChangedValues = (initialValues: Record<string, string | undefined>, formValues: FormValues) => {
    const changedValues: FormValues = {};

    Object.keys(initialValues).forEach(key => {
      if (initialValues[key] !== formValues[key]) {
        changedValues[key] = formValues[key];
      }
    });
    Object.keys(formValues).forEach(key => {
      if (!(key in initialValues)) {
        changedValues[key] = formValues[key];
      }
    });

    return changedValues;
  };

  const onSubmit = () => {
    const formValues = form.getFieldsValue(true) as FormValues;
    const changedValues = getChangedValues(initialValues, formValues);

    const values = Object.entries(changedValues).flatMap(([key, value]) => {
      const [code, fieldCode] = key.split("@");

      const transformedValues: BlockCode[] = [];
      const suffixes = ["man", "tib1", "tib2", "fa", "sa"];
      const matchedSuffix = suffixes.find(suffix => fieldCode.endsWith(`-${suffix}`));

      if (matchedSuffix) {
        const baseFieldCode = fieldCode.slice(0, -matchedSuffix.length - 1);

        switch (value) {
          case "10":
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-set`, value: "true" });
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-rem`, value: "false" });
            break;
          case "01":
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-set`, value: "false" });
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-rem`, value: "true" });
            break;
          case "11":
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-set`, value: "true" });
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-rem`, value: "true" });
            break;
          case "00":
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-set`, value: "false" });
            transformedValues.push({ code, fieldCode: `nic-${baseFieldCode}-${matchedSuffix}-rem`, value: "false" });
            break;
          default:
            break;
        }
      } else {
        transformedValues.push({ code, fieldCode, value: value as string });
      }

      return transformedValues;
    });

    const uniqueBlockCodes = [...new Set(values.map(({ code }) => code))];

    const hasMissingFields = uniqueBlockCodes.some(code => {
      const missingFields = validateRequiredFields(formValues, dataSource, code!);

      const fieldNames: Record<string, string> = {
        "nic-bc-name": "Name",
        "nic-bc-prior": "Priority",
        atLeastOneFilled: 'At least one option from "Could be applied on" must be filled.',
      };

      if (missingFields.length > 0) {
        notification.error({
          placement: "topRight",
          duration: 10,
          message: (
            <span>
              Block code &quot;{code}&quot; is missing the following required fields:
              <ul className="m-b-0">
                {missingFields.map(x => (
                  <li key={x}>{fieldNames[x]}</li>
                ))}
              </ul>
            </span>
          ),
        });
        return true;
      }
      return false;
    });

    if (hasMissingFields) return;

    const requestBody: ChangeTenantRequest = {
      blockCodes: values,
    };

    setIsLoading(true);

    tenantServiceApi
      .editTenant(requestBody, parseInt(tenantId ?? "0", 10))
      .then(res => {
        setTenant(res.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const onRestore = (blockCode: string) => {
    setIsLoading(true);
    setCodeToRestore(null);

    let blockCodes: BlockCode[] = [];

    tenantServiceApi
      .getDefaultBlockCodesByCode(blockCode)
      .then(resp => {
        resp.data.forEach(bc => {
          blockCodes = [
            ...blockCodes,
            {
              code: bc.code,
              fieldCode: bc.fieldCode,
              value: bc.value,
            },
          ];
        });

        blockCodes = [
          ...blockCodes,
          {
            code: blockCode,
            fieldCode: ProductBcParams.alreadyEdited,
            value: "false",
          },
        ];

        const requestBody: ChangeTenantRequest = {
          blockCodes,
        };

        tenantServiceApi
          .editTenant(requestBody, parseInt(tenantId ?? "0", 10))
          .then(res => {
            setTenant(res.data);
            notification.success({
              placement: "topRight",
              duration: 10,
              message: "Success",
              description: `Default configuration was restored for block code "${blockCode}"`,
            });
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      })
      .catch(() => setIsLoading(false));
  };

  const checkAll = codes.length === visibleColumns.length;
  const indeterminate = visibleColumns.length > 0 && visibleColumns.length < codes.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setVisibleColumns(codes);
    } else {
      setVisibleColumns([]);
    }
  };

  return (
    <Space direction="vertical" size={8} className="w-p-100">
      <Flex justify="flex-end" align="center" gap={16}>
        <NetworkForm.Select
          mode="multiple"
          maxTagCount={3}
          allowClear={true}
          showSearch={true}
          value={visibleColumns}
          onChange={setVisibleColumns}
          optionList={codes.map(x => ({ label: x, value: x }))}
          className={styles["select"]}
          dropdownRender={menu => (
            <>
              <Space direction="vertical" style={{ padding: "8px 0 4px 8px" }}>
                <Checkbox indeterminate={indeterminate} checked={checkAll} onChange={onCheckAllChange}>
                  <Typography.Text strong={true}>Select all</Typography.Text>
                </Checkbox>
              </Space>
              <Divider style={{ margin: "8px 0" }} />
              {menu}
            </>
          )}
        />
        <SMCViewToggles />
      </Flex>

      <Form form={form} component={false} onFinish={onSubmit}>
        <Space direction="vertical" size={48} className="w-p-100">
          <Form.Item dependencies={dependencies} noStyle={true}>
            {() => (
              <Table
                size="small"
                rowKey="key"
                columns={columns}
                dataSource={tableData}
                components={components}
                expandable={expandable}
                loading={isLoading}
                bordered={true}
                pagination={false}
                rowClassName={record => (record.isCategory ? styles["category-row"] : "")}
                scroll={{ x: visibleColumns.length * blockCodeWidth + labelWidth, y: !isPrintMode ? 715 : undefined }}
                className={styles["smc-table"]}
              />
            )}
          </Form.Item>

          {!isPrintMode && (
            <Button
              className={styles["button"]}
              size="large"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
              disabled={isLoading}
            >
              Save
            </Button>
          )}
        </Space>
      </Form>

      <Modal
        title="Confirmation"
        width="500px"
        open={!!codeToClear}
        onCancel={() => setCodeToClear(null)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setCodeToClear(null)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => {
              onReset(codeToClear!);
              form.resetFields(Object.keys(initialValues).filter(x => x.startsWith(codeToClear!)));
              setCodeToClear(null);
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <p>Are you sure you want to clear block code &quot;{codeToClear}&quot; values?</p>
      </Modal>

      <Modal
        title="Confirmation"
        width="500px"
        open={!!codeToRestore}
        onCancel={() => setCodeToRestore(null)}
        footer={[
          <Button key="back" disabled={isLoading} onClick={() => setCodeToRestore(null)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={() => {
              onRestore(codeToRestore!);
            }}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>Restoring default settings for block code &quot;{codeToRestore}&quot; will remove all your changes.</p>
      </Modal>
    </Space>
  );
});

export { SMCTable };
