import { useMemo, useState } from "react";
import { Select, Space, Typography } from "antd";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import { SearchOutlined } from "@ant-design/icons";
import { PCT_PAGES_CODES, PRODUCT_PAGES_CODES, TENANT_PAGES_CODES } from "@ni/common/constants";
import { Tenant } from "@ni/sdk/models";

const splitter = "{{index}}";

export const CodePagePicker = ({ tenant }: { tenant: Tenant }) => {
  const { pathname } = useLocation();
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const { tenantId, products } = useMemo(
    () => ({
      tenantId: tenant?.id,
      products: tenant?.products?.map(product => ({
        id: product?.id,
        name: product?.displayName,
        pcts: product?.parameterTables?.map(pct => ({ id: pct.id, name: pct.displayName })),
      })),
    }),
    [tenant?.id, tenant?.products],
  );

  const requestedRoutes = useMemo(() => {
    const routesList: { label: string; value: string; url: string }[] = [];

    Object.keys(TENANT_PAGES_CODES as Record<string, string[]>).forEach(key => {
      if (
        typeof key === "string" &&
        TENANT_PAGES_CODES[key as keyof typeof TENANT_PAGES_CODES]
          .join(" ")
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase())
      ) {
        routesList.push({
          label: `${_.upperFirst(key.replace(/-/g, " "))} (Tenant)`,
          value: `${routesList.length} ${splitter} ${search} ${key}`,
          url: `/tenant/${tenantId}/${key}`,
        });
      }
    });

    products?.forEach(product => {
      Object.keys(PRODUCT_PAGES_CODES as Record<string, string[]>).forEach(key => {
        if (
          typeof key === "string" &&
          PRODUCT_PAGES_CODES[key as keyof typeof PRODUCT_PAGES_CODES]
            .join(" ")
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        ) {
          routesList.push({
            label: `${_.upperFirst(key.replace(/-/g, " "))} (Product) (${product?.name})`,
            value: `${routesList.length} ${splitter} ${search} ${key}`,
            url: `/tenant/${tenantId}/product/${product?.id}/${key}`,
          });
        }
      });

      product.pcts?.forEach(pct => {
        Object.keys(PCT_PAGES_CODES as Record<string, string[]>).forEach(key => {
          if (
            typeof key === "string" &&
            PCT_PAGES_CODES[key as keyof typeof PCT_PAGES_CODES]
              .join(" ")
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          ) {
            routesList.push({
              label: `${_.upperFirst(key.replace(/-/g, " "))} (PCT) (${pct?.name} From ${product.name})`,
              value: `${routesList.length} ${splitter} ${search} ${key}`,
              url: `/tenant/${tenantId}/product/${product?.id}/pct/${pct?.id}/${key}`,
            });
          }
        });
      });
    });

    return routesList;
  }, [products, search, tenantId]);

  const onRouteSelection = (_: string, option: unknown) => {
    const index = (option as { label: string })?.label;
    if (index && Number.isInteger(Number(index))) {
      navigate(requestedRoutes[Number(index)].url);
    }
  };

  if (/^\/tenant/.test(pathname))
    return (
      <Select
        showSearch={true}
        size="large"
        placeholder="Search by code"
        suffixIcon={<SearchOutlined />}
        onChange={onRouteSelection}
        allowClear={true}
        popupMatchSelectWidth={false}
        onSearch={setSearch}
        onClear={() => setSearch("")}
        labelRender={options => (
          <Typography.Link type="danger">{(options as { title?: string })?.title}</Typography.Link>
        )}
        autoClearSearchValue={true}
      >
        {requestedRoutes.map((route, index) => (
          <Select.Option key={route.value} title={route.label} label={`${index}`}>
            <Space direction="vertical" size="small">
              <Typography.Link type="danger">{route.label}</Typography.Link>
              <Typography.Text type="secondary">{route.url}</Typography.Text>
            </Space>
          </Select.Option>
        ))}
      </Select>
    );

  return null;
};
