export const PRODUCT_PAGES_CODES = {
  "product-details": [
    "nic-prod-ips",
    "nic-prod-core-name",
    "nic-ccy",
    "product-type",
    "nic-card-subtype-pan-bin",
    "use-test-bin",
    "nic-card-subtype-pan-full-min",
    "nic-card-subtype-pan-full-max",
    "is-co-badged-product",
    "nic-mada-use",
    "co-badged-name",
    "credit-type",
    "credit-type-islamic",
    "add-prod-card-control",
  ],
  "currency-setup": ["nic-ccy", "nic-prod-ppm-w00"],
  "card-numbering": [
    "nic-card-subtype-pan-bin",
    "nic-prod-parms-ica-number",
    "use-test-bin",
    "nic-card-subtype-pan-full-min",
    "nic-card-subtype-pan-full-max",
    "ica-number-mc",
    "rep-bin-visa",
    "nic-prod-ips",
    "bin-length",
  ],
  "credit-limit": [
    "nic-crlim-min",
    "nic-crlim-max",
    "nic-crlim-default",
    "nic-cash-limit-acc-rate",
    "nic-cash-limit-card-rate",
    "nic-ovl-auth-var",
    "nic-ch_agrm-canc-crlim-nullify-acc",
    "nic-ch_agrm-canc-crlim-nullify-card",
    "nic-nullify-crlim-acc-acc_st-8",
    "nic-nullify-crlim-acc-acc_st-9",
    "nic-nullify-crlim-acc-acc_st-z",
  ],
  "payment-and-delinquency-settings": [
    "nic-due-date-days",
    "nic-dlq-date-days",
    "sms-due-reminder-flag",
    "nic-sms-due-reminder-days",
    "nic-fp-date-days",
    "nic-fp-date-base",
    "nic-lp-date-days",
    "nic-lp-date-base",
    "nic-dlq-date-base",
  ],
  "minimum-to-pay-formula": ["nic-mtp-am-formula", "nic-mtp-am-mode"],
  "murabaha-profit-settings": [
    "nic-int-rate-zero-all-fees",
    "nic-int-rate-zero-all-int",
    "murab-profit-rate",
    "int-start",
    "int-start-numb",
  ],
  "tawarruq-profit-settings": [
    "tawarruq-profit-type",
    "tawarruq-profit-percentage",
    "tawarruq-profit-amount",
    "tawarruq-sales-fee",
    "tawarruq-sales-fee-type",
    "tawarruq-sales-fee-percentage",
    "tawarruq-sales-fee-amount",
  ],
  "interest-grace-options": [
    "nic-int-grace-retail",
    "nic-int-grace-cash",
    "nic-int-rate-zero-all-fees",
    "nic-int-rate-zero-all-int",
    "grace-opt",
  ],
  "direct-debit-configuration": [
    "nic-dd-date-mode",
    "nic-dd-date-day",
    "nic-dd-block-activ-cycle",
    "nic-sms-dd-request-days",
    "dd-amount-calc",
    "nic-dd-am-formula",
    "nic-dd-am-mode",
    "dd-recovery-mode",
    "nic-dd-mbr",
    "nic-dd-flag-def",
    "dd-daily-recovery-opt",
  ],
  "card-and-pin-maintenance": [
    "virt-phys",
    "nic-card-subtype-exp-months",
    "nic-card-subtype-autorenew-days",
    "nic-card-carr-address-lev-prim",
    "nic-card-carr-address-lev-suppl",
    "reiss-phys",
    "nic-preemb-auto-activate",
    "nic-card-subtype-pinset-allowed",
    "nic-card-subtype-pinset-allowed-for-locked",
    "sde",
    "PERSO_SUBSTANCE",
    "perso",
  ],
  "payment-schemas-services": [
    "nic-card-subtype-abu-active",
    "nic-card-subtype-token-used",
    "nic-card-subtype-token-mada-used",
  ],
  "auto-closure": [
    "auto-closure-enabled",
    "nic-acc-st-auto-close-per-type",
    "nic-acc-st-auto-close-per",
    "nic-acc-st-auto-dorm-eod",
  ],
  "fees-settings": [
    "amf-acc-moment",
    "amf-card-moment",
    "nic-amf-card-waive-unexp",
    "nic-mmf-card-waive-unexp",
    "nic-inact-period-unit",
    "nic-inact-period",
    "nic-ovl-fee-repeat-mode",
    "nic-amf-acc-start-mode",
    "nic-amf-card-start-mode",
    "nic-stmt-fee-flag",
  ],
  "loyalty-settings": [
    "nic-lty-flag-def",
    "nic-lty-flag-editable",
    "nic-lty-accr-yearly-start",
    "lty-red-cash-a-tot",
    "nic-lty-red-cash-a-tot",
    "nic-lty-red-ext-a-tot-mbr",
    "lty-welc-bonus-target",
    "nic-lty-bonus_w-timer-from",
    "nic-lty-bonus_w-timer-per-type",
    "nic-lty-bonus_w-timer-per",
  ],
  "sms-notification": [
    "nic-stmt-flag-def-new",
    "nic-autorenew-event-per",
    "nic-autorenew-event-per-type",
    "nic-sms-flag-def-new",
  ],
  "manual-closure": ["nic-acc-st-man-close-days", "nic-card-st-man-close-days"],
  purge: [
    "nic-acc-st-auto-purge-per-type",
    "nic-acc-st-auto-purge-per",
    "nic-card-st-auto-purge-per-type",
    "nic-card-st-auto-purge-per",
  ],
};
