import { forwardRef, ReactNode, useRef } from "react";
import { Form, InputNumber, InputNumberProps, TooltipProps } from "antd";

import { NetWorkFormItemProps } from "@ni/common/types";
import { numberFormatter, numberParser, onKeyDownPreventChars } from "@ni/common/utils";

import { TooltipInfo } from "../../TooltipInfo";

export type NetworkNumberProps = { formItemOptions?: NetWorkFormItemProps } & InputNumberProps;

export const Number = forwardRef<HTMLInputElement, NetworkNumberProps>(({ formItemOptions, ...props }, ref) => {
  const isBlurred = useRef<boolean>(true);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (props?.onKeyDown) {
      props.onKeyDown(e);
    }

    const { key } = e;
    onKeyDownPreventChars(e);

    if (!props?.precision && key === ".") e.preventDefault();
    if (key === "." && e.currentTarget.value.includes(".")) e.preventDefault();
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (props?.onPaste) {
      props.onPaste(e);
    }

    const paste = e.clipboardData.getData("text");
    if (!/^\d*\.?\d*$/.test(paste)) e.preventDefault();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (props?.onBlur) {
      props.onBlur(event);
    }
    isBlurred.current = true;
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (props?.onFocus) {
      props.onFocus(event);
    }
    isBlurred.current = false;
  };

  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        tooltip={undefined}
        label={
          <TooltipInfo
            label={formItemOptions?.label as string}
            code={formItemOptions?.name as string}
            tooltipProps={
              (formItemOptions?.tooltip
                ? typeof formItemOptions.tooltip === "string"
                  ? { title: formItemOptions?.tooltip }
                  : typeof formItemOptions.tooltip === "object" &&
                      (formItemOptions.tooltip as { title: ReactNode })?.title
                    ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                    : formItemOptions.tooltip
                : {}) as TooltipProps
            }
          />
        }
      >
        <InputNumber
          ref={ref}
          formatter={(value, info) => numberFormatter(value, info, props?.precision ?? 0, isBlurred.current)}
          parser={numberParser}
          controls={false}
          {...props}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Form.Item>
    );
  }
  return (
    <InputNumber
      ref={ref}
      formatter={(value, info) => numberFormatter(value, info, props?.precision ?? 0, isBlurred.current)}
      parser={numberParser}
      controls={false}
      {...props}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
});
